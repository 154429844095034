<template>
    <div class="content">
        <el-upload :action='action' :before-upload="beforeAvatarUpload" :on-preview="handlePreview"
            :before-remove="beforeRemove" :on-remove="handleRemove" :on-success="handleSuccess"
            :on-exceed="handleExceed" drag :limit="limit" :file-list="fileList">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
                将音频文件拖到此处，或
                <em>点击上传</em>
            </div>
            <div slot="tip" class="el-upload__tip">上传文件大小不能超过 5M</div>
        </el-upload>

        <el-progress style="width: 300px;" v-show="showProgress" :text-inside="true" :stroke-width="15"
            :percentage="progress"></el-progress>
    </div>
</template>
<script>
import { client, getFileNameUUID } from '../src/common/js/oss.js'

export default {
    props: {
        maxDuration: {
            type: Number,
            default: 0
        },
        minDuration: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            action: 'https://xinouyou.oss-cn-shanghai.aliyuncs.com',
            fileList: [],//文件列
            showProgress: false,//进度条的显示
            dataObj: {
                region: 'oss-cn-shanghai',
                accessKeyId: 'LTAI5tRawNfEmzasXi9fFiT9',
                accessKeySecret: 'R4N8J4gkihfUwHXgrTH8CI5FwWEFW2',
                bucket: 'xinouyou'
            }, //存签名信息
            progress: 0, //进度条数据
            limit: 1,
            playTime: 0,

            time: null,
            duration: '',//音频时长


            fullscreenLoading: false//加载


        }
    },
    methods: {
        // 文件超出个数限制时的钩子
        handleExceed(files, fileList) {
            this.$message.warning(`每次只能上传 ${this.limit} 个文件`);
        },
        // 点击文件列表中已上传的文件时的钩子
        handlePreview(file) { },
        // 删除文件之前的钩子
        beforeRemove(file, fileList) {
            // return this.$confirm(`确定移除 ${file.name}？`);
            this.handleRemove()
        },
        // 文件列表移除文件时的钩子
        handleRemove(file, fileList) {
            this.showProgress = false
        },
        // 文件上传成功时的钩子
        handleSuccess(response, file, fileList) {
            this.fileList = fileList;
        },
        // 获取音视频时长
        getVideoPlayerInfo(file) {
            return new Promise(resolve => {
                let videoElement = document.createElement('video');
                videoElement.src = URL.createObjectURL(file);
                videoElement.addEventListener('loadedmetadata', function () {
                    resolve({
                        duration: videoElement.duration,
                        width: videoElement.videoWidth,
                        height: videoElement.videoHeight
                    });
                });
            });
        },
        //文件上传前的校验
        beforeAvatarUpload(file) {
            // console.log(file)
            let that = this
            that.getVideoPlayerInfo(file).then(videoInfo => {
                const { duration, width, height } = videoInfo;
                // console.log(duration, 'xixi')
                that.$emit('audioDuration', duration)
                that.duration = Math.floor(duration)
            });
            const loading = that.$loading({ lock: true, text: 'Loding', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.5)' });

            that.time = setTimeout(function () {
                that.time = null;
                loading.close();

                // console.log(that.duration, '音频时长')
                // console.log(that.minDuration, '音频时长')
                // console.log(that.maxDuration, '音频时长')
                if (that.duration < that.minDuration) {
                    that.$message.error("音频时长应在" + that.minDuration + 's至' + that.maxDuration + 's内');
                    return
                }
                if (that.duration > that.maxDuration) {
                    that.$message.error("音频时长应在" + that.minDuration + 's至' + that.maxDuration + 's内');
                    return
                }
                const isLt5M = file.size / 1024 / 1024 < 5
                if (["audio/mpeg",].indexOf(file.type) == -1) {
                    that.$message.error("请上传正确的音频格式");
                    return false;
                }
                if (!isLt5M) {
                    that.$message.error("上传音频大小要小于5M哦!");
                    return false;
                }
                that.Upload(file)
                

            }, 1000)



        },
        Upload(file) {
            // let timer = setTimeout(() => {
            //     clearTimeout(timer)

            // }, 2000)
            const that = this;
            async function multipartUpload() {
                let temporary = file.name.lastIndexOf(".");
                let fileNameLength = file.name.length;
                let fileFormat = file.name.substring(
                    temporary + 1,
                    fileNameLength
                );
                let fileName = getFileNameUUID() + "." + fileFormat;

                client(that.dataObj)
                    .multipartUpload(`videoTest/${fileName}`, file, {
                        progress: function (p) {
                            //p进度条的值
                            // console.log(p);
                            that.showProgress = true;
                            that.progress = Math.floor(p * 100);
                        }
                    })
                    .then(result => {
                        //上传成功返回值，可针对项目需求写其他逻辑
                        let path = result.res.requestUrls[0]
                        if (path.indexOf("?") != -1) {
                            path = path.split("?")[0];
                            that.$emit('audioPath', path)
                            // 这是OSS传回来的数据
                        }
                    })
                    .catch(err => {
                        console.log("err:", err);
                    });
            }
            multipartUpload();
        }
    }

}
</script>
<style lang="less" scoped>
.content {
    /deep/.el-upload-list {
        width: 300px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    /deep/.el-upload-dragger {
        width: 517px;
        height: 161px;
    }
}
</style>