<template>
    <!-- 点数购买 -->
    <div class="pointPurchase">
        <div class="hearde">
            <div class="title">点数充值</div>
            <div class="balance">当前余额：{{ balance }}</div>
        </div>
        <div class="tabList">
            <div class="item" v-for="(item, index) in tabList.slice(0, 2)" :key="index" @click="selectTab(index)"
                :class="{ active: tabIndex === index }">
                <div class="points">{{ item.points }}<span>点数</span></div>
                <div class="price">{{ item.price }}元</div>
            </div>
        </div>
        <div class="itemTwo">
            <div>
                <div class="box left" v-for="(item, index) in tabList.slice(2, 3)" :class="{ active: alone == 'true' }"
                :key="index" @click="clickLeft()">
                <div class="points">{{ item.points }}<span>点数</span></div>
                <div class="price">{{ item.price }}元</div>
            </div>
            </div>
            <div class=" custom" @click="clickCustom()" :class="{ active: alone == 'false' }">
                自定义充值
            </div>
        </div>

        <!-- 扫码支付 -->
        <div class="scanPay">
            <div class="title">扫码支付</div>
            <div class="contentBox">
                <div class="scan">
                    <img :src="scan" alt="">
                </div>
                <div class="introduce">
                    <div></div>
                    <div>
                        <div class="price"> 应付金额<span class="span1">￥</span><span class="span2"></span></div>
                        <div class="wxPay"><img src="../assets/wxPay.png" alt="">使用微信支付</div>
                        <div class="agreement">已阅读并同意<span>《充值协议》</span></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        pointPurchaseShow: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            balance: '',
            tabIndex: 0,
            tabList: [
                { points: 100, price: 10 },
                { points: 500, price: 50 },
                { points: 1000, price: 100 },
            ],
            alone: '',
            // scan: require('../assets/xcx.png'),
            scan: '',


            price:''

        }
    },
    methods: {
        selectTab(index) {
            this.tabIndex = index
            this.alone = ' '
            this.price = this.tabList[index].price
        },
        clickLeft() {
            this.tabIndex = ''
            this.alone = 'true'
            this.price = this.tabList[2].tabList

        },
        clickCustom(){
            this.tabIndex = ''
            this.alone = 'false'
        },
    },
    created() {
        this.balance = JSON.parse(localStorage.getItem('userInfo')).price
    }
}
</script>
<style lang="less" scoped>
/deep/.el-dialog__body {
    height: 440px;
}

.pointPurchase {

    .hearde {
        display: flex;
        line-height: 30px;

        .title {
            font-size: 20px;
            color: #091221;
            white-space: nowrap;
        }

        .balance {
            margin-left: 15px;
            color: #091221;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

.tabList {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .item {
        width: 150px;
        height: 88px;
        font-size: 20px;
        color: #000;
        cursor: pointer;
        border-radius: 10px;
        background: #E2F1FF;
        text-align: center;

        .points {
            line-height: 50px;

            span {
                font-size: 16px;

            }
        }

        .price {
            font-size: 12px;
        }
    }

    .item.active {
        width: 148px;
        height: 86px;
        background: rgba(255, 236, 232, 0.5);
        border: 1px solid #FF0000;
    }
}

.itemTwo {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    .box {
        width: 150px;
        height: 88px;
        font-size: 20px;
        color: #000;
        cursor: pointer;
        border-radius: 10px;
        background: #E2F1FF;
        text-align: center;
        .points {
            line-height: 50px;

            span {
                font-size: 16px;

            }
        }

        .price {
            font-size: 12px;
        }
    }
    .box.active {
        width: 148px;
        height: 86px;
        background: rgba(255, 236, 232, 0.5);
        border: 1px solid #FF0000;
    }
    .custom{
        width: 150px;
        text-align: center;
        height: 88px;
        line-height: 88px;
        font-size: 20px;
        color: #000;
        cursor: pointer;
        border-radius: 10px;
        background: #E2F1FF;
        text-align: center;
    }
    .custom.active {
        width: 148px;
        height: 86px;
        background: rgba(255, 236, 232, 0.5);
        border: 1px solid #FF0000;
    }

   
}

.scanPay {
    .title {
        font-size: 22px;
        color: #091221;
        line-height: 45px;
    }

    .contentBox {
        border-radius: 10px;
        background: #E2F1FF;
        height: 120px;
        padding: 15px;
        display: flex;

        .scan {
            img {
                width: 125px;
                height: 125px;
            }
        }

        .introduce {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-left: 10px;

            .price {
                font-size: 14px;

                color: #000000;

                .span1 {
                    color: red;
                }

                .span2 {
                    font-size: 20px;
                }
            }
            .agreement{
                font-size: 12px;
                span{
                    color: #3E7AFE
                }
            }
        }
    }
}
</style>