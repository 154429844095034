<template>
  <div class="home">
    <div>
      <el-container>
        <el-header style="height: 67px;">
          <Header></Header>
        </el-header>
        <el-container>
          <el-aside>
            <Aside></Aside>
          </el-aside>
          <el-main style="  overflow-x: hidden">
            <Main></Main>
          </el-main>
        </el-container>
      </el-container>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Aside from '@/components/Aside.vue'
import Main from '@/components/Main.vue'

export default {
  name: 'HomeView',
  components: {
    Header,
    Aside,
    Main
  },
  data() {
    return {
    
    }
  },

  created(){
    // this.$bus.$on('busData', msg => { })
  }
}
</script>
<style lang="less">
body {
  background: radial-gradient(70% 70% at 50% 50%, #E4EEFB 0%, rgba(240, 230, 250, 0.33) 27%, rgba(229, 233, 250, 0.476) 69%, #DFEEFF 100%), #FFFFFF;
}
.home {
 
  .el-main {
    padding-top: 0;
  }

}
</style>
