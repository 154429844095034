<template>
    <div class="content">
        <div class="play_box">
            <div class="box">
                <audio ref="audio" id="audio" :src="aPath" @pause="onPause" @play="onPlay" @timeupdate="onTimeupdate"
                    @loadedmetadata="onLoadedmetadata">
                    您的浏览器不支持audio标签
                </audio>
                <div class="custom-audio clearfix">
                    <div class="audio-item play" @click="play" v-if="!audio.playing">
                        <img src="../src/assets/bofang3.png" width="20" />
                    </div>
                    <div class="audio-item play" @click="pause" v-if="audio.playing">
                        <img src="../src/assets/zanting2.png" width="20" />
                    </div>

                    <!-- <div class="audio-item play-time"></div> -->
                    <div class="audio-item progress">
                        <el-slider @change="progressChange($event)" @mouseup.native="mouseupChangeTime"
                            v-model="audio.currentTime" :format-tooltip="realFormatSecond"
                            :max="audio.maxTime"></el-slider>
                    </div>
                    <div class="audio-item total-time">{{ audio.currentTime | formatSecond }}/{{ audio.maxTime |
                        formatSecond }}</div>
                    <el-tooltip class="item" effect="light" placement="top">
                        <div slot="content">
                            <el-slider vertical height="120px" :step="0.01" :max="1" v-model="audio.volume"
                                @input="voiceChange()" :format-tooltip="handelVoice"></el-slider>
                        </div>
                        <div class="audio-item mute" @click="mute" v-if="!audio.muted">
                            <img src="../src/assets/yinliang.png" width="20" />
                        </div>
                        <div class="audio-item mute" @click="cancelMute" v-if="audio.muted">
                            <img src="../src/assets/jingyin.png" width="20" />
                        </div>
                    </el-tooltip>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
function realFormatSecond(second) {
    var secondType = typeof second;

    if (secondType === 'number' || secondType === 'string') {
        second = parseInt(second);

        var hours = Math.floor(second / 3600);
        second = second - hours * 3600;
        var mimute = Math.floor(second / 60);
        second = second - mimute * 60;

        return ('0' + mimute).slice(-2) + ':' + ('0' + second).slice(-2)
    } else {
        return '00:00'
    }
}

export default {
    name: "CustomAudio",
    props: {
        aPath: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            value: 20,
            audio: {
                // 播放状态
                playing: false,
                // 静音状态
                muted: false,
                // 音频当前播放时长
                currentTime: 0,
                // 音量
                volume: 1,
                // 音频最大播放时长
                maxTime: 0
            },
            cacheCurrent: 0,
            cacheVoice: 1,
        }
    },
    methods: {
        // 音频相关方法
        // 播放音频
        play() {
            if (this.aPath == '') {
                this.$message({
                    message: '请刷新后在试听',
                    type: 'warning'
                });
                return
            }
            document.querySelectorAll('audio').forEach(el => el.pause());//关闭所有音频
            this.$refs.audio.play()
        },
        // 暂停音频
        pause() {
            this.$refs.audio.pause()
        },
        // 当音频播放
        onPlay() {
            this.audio.playing = true
        },
        // 当音频暂停
        onPause() {
            this.audio.playing = false
        },
        // 拖动音量滚动条
        voiceChange() {
           
            this.cancelMute(false);
            if (this.audio.volume === 0) {
                this.mute(false)
            }
            this.$refs.audio.volume = this.audio.volume;
        },
        // 静音
        mute(event) {
            event && (this.cacheVoice = this.audio.volume);
            this.audio.volume = 0;
            this.audio.muted = true;
            this.$refs.audio.muted = true;
        },
        // 取消静音
        cancelMute(event) {
            event && (this.audio.volume = this.cacheVoice);
            this.audio.muted = false;
            this.$refs.audio.muted = false;
        },
        // 鼠标抬起改变当前时间点
        mouseupChangeTime() {
            
            this.progressChange(this.cacheCurrent)
        },
        // 拖动进度滚动条
        progressChange(value) {
            this.$refs.audio.currentTime = value >= 0 ? value : this.cacheCurrent;
            this.audio.currentTime = value >= 0 ? value : this.cacheCurrent
        },
        // 当timeupdate事件大概每秒一次，用来更新音频流的当前播放时间
        onTimeupdate(res) {
            this.audio.currentTime = res.target.currentTime
        },
        // 获取音频长度
        onLoadedmetadata(res) {
            this.audio.maxTime = parseInt(res.target.duration)
        },
        realFormatSecond(second) {
            this.cacheCurrent = second;
            return realFormatSecond(second);
        },
        // 处理音量显示
        handelVoice() {
            return parseInt(this.audio.volume.toFixed(2) * 100);
        }
    },
    filters: {
        // 将整数转化成时分秒
        formatSecond(second = 0) {
            return realFormatSecond(second)
        }
    },
    created() {
        window.myData = this;//将变量全局化,这样浏览器可以查看当前data是什么情况::myData._data
    },
}

</script>

<style scoped lang="less">
.play_box {

    // width: 517px;
    // height: 161px;
    // border-radius: 5px;
    // border: 1px solid #CCCACA;
    // display: flex;
    // justify-content: center;
    .box {
        // margin-top: 30px;
        width: 477px;
        height: 41px;
        box-sizing: border-box;
        border: 1px solid #ABABAB;
        border-radius: 5px;

        .custom-audio {
            display: flex;

            .audio-item {
                margin-top: 8px;
                margin-left: 10px;

                img {
                    width: 22px;
                    height: 22px;
                }
            }

            .play-time {
                width: 60px;
                margin-top: 8.5px;
            }

            .progress {
                width: calc(100% - 198px);
                margin: 0;
                margin-left: 20px;
            }

            .total-time {
                margin-top: 8.5px;
            }

            .mute {
                margin-top: 10px;

                img {
                    width: 22px;
                    height: 16px;
                }
            }
        }
    }
}
</style>