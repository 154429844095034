<template>
    <!-- 音频抽屉 -->
    <div class="audioDrawer">

        <div class="actuate_box">
            <div class="tab-header">
                <div class="tab-background" :style="backgroundStyle"></div>
                <div class="tab-slider" :style="sliderStyle"></div>
                <button v-for="(tab, index) in drivens" :key="tab.id" @click="selectTabDrawer(tab.id, index)"
                    :class="{ active: drivenIn === tab.id }">
                    {{ tab.title }}
                </button>
            </div>
            <!-- 文字驱动 -->
            <div class="textDriven" v-if="activeIndex == 0">
                <div class="textComposition">
                    <div class="goCraft">
                        <div class="left">音频选择</div>
                        <div class="right" @click="desynthesis">去合成音频</div>
                    </div>
                </div>
                <!-- 搜索 -->
                <div class="search">
                    <el-input placeholder="请输入内容" @change="search" prefix-icon="el-icon-search" v-model="searchValue">
                    </el-input>
                </div>
                <!-- 表格 -->
                <div class="">
                    <tabulation :items="SyntheticAudioList" @selection-change="handleSelectionChange"
                        @delete-item="handleDeleteItem" @download-item="handleDownloadItem"
                        @refresh-item="handleRefreshItem" />
                </div>
                <!-- 分页 -->
                <div class="block">
                    <el-pagination @current-change="handlePageChange" :page-sizes="[10]" :page-size="10"
                        layout="total, sizes, prev, pager, next, jumper" :total="SyntheticAudioTotal">
                    </el-pagination>
                </div>
                <!-- 确认 -->

            </div>

            <div class="audioDriver" v-if="activeIndex == 1">
                <div class="upload">
                    <div v-if="!audioPathOss">
                        <audioUpload @audioPath="audioPath" @audioDuration="audioDuration" :maxDuration="9999" />
                    </div>
                    <div class="audioPlayer" v-if="audioPathOss">
                        <audioPlayer :aPath="audioPathOss" />
                        <div class="reupload" @click="reupload">点击重新上传文件</div>
                    </div>
                </div>
                <!-- 录制建议 -->
                <div class="suggestion">
                    <div>
                        <div class="title">录制建议</div>
                        <div class="item" v-for="(item, index) in suggestionList" :key="index">
                            <div class="itemTile">{{ item.title }}</div>
                            <div class="itemContent" v-for="(ite, ind) in item.text" :key="ind">
                                {{ ite }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="verify_cancel">
                <div class="verify" @click="verify">
                    确认
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import tabulation from '../../../components/tabulation.vue';
import audioUpload from '../../../components/audioUpload.vue'
import audioPlayer from '../../../components/AudioPlayer.vue'

import { getSyntheticAudioList, deleteSyntheticAudio } from '../../../api/api'

export default {
    props: {
        audioDrawer: {
            type: Boolean,
            default: null
        }
    },
    components: {
        tabulation,
        audioUpload,
        audioPlayer
    },
    computed: {
        sliderStyle() {
            return {
                transform: `translateX(${this.activeIndex * 100}%)`
            }
        },
        backgroundStyle() {
            return {
                transform: `translateX(${this.activeIndex * 100}%)`
            }
        },

        sliderStyleImage() {
            return {
                transform: `translateX(${this.activeImageIndex * 100}%)`
            }
        },
        backgroundStyleImage() {
            return {
                transform: `translateX(${this.activeImageIndex * 100}%)`
            }
        },
    },
    data() {
        return {

            activeIndex: 0,//控制文本-语音切换
            drivenIn: 'textDriven',
            drivens: [
                { id: 'textDriven', title: '文本驱动' },
                { id: 'audioDriver', title: '音频驱动' }
            ],
            searchValue: '',//文本驱动搜索
            suggestionList: [
                {
                    title: '录制文本',
                    text: [
                        '1.克隆声音会保留上传音频的语气风格，为避免语气违和，需 准备贴近最终使用场景的文本，如用于电商直播的声音使用。 电商带货文本进行录制。'
                    ]
                },
                {
                    title: '录制环境',
                    text: [
                        '1.保障无环境背景噪声、无明显回声和混响、信噪比大于30dB。',
                        '2.录制期间话筒距离嘴部10cm-50cm之间，保持位置距离稳定。',
                        '3.录制过程中保持姿态稳定，减少人为噪声，如肢体碰撞声、 服装摩擦声、桌椅移动声、鼠标键盘声等。'
                    ]
                }
            ],
            SyntheticAudioList: [],//合成音频列表
            SyntheticAudioPage: 1,//合成音频列表分页
            SyntheticAudioTotal: 0,//合成音频列表总数

            selectedItem: {},//文本合成选中的音频
            audioPathOss: '',//上传到OSS返回的音频地址

            duration: '',//音频驱动，上传音频的时长

            dataItem: {},


        }
    },
    methods: {

        // 控制文本合成音频框
        desynthesis() {
            this.$emit('audioShow', true)
        },
        selectTabDrawer(tabId, index) {
            this.activeIndex = index;
        },

        handleSelectionChange(selectedItem) {
            // console.log('选中的文本合成音频', selectedItem);
            this.selectedItem = selectedItem
        },
        handleDeleteItem(item) {
            // 实现删除逻辑
            console.log('Deleting item:', item);
            const index = this.SyntheticAudioList.findIndex(human => human.name === item.name);
            if (index !== -1) {

                let data = { id: item.id }
                deleteSyntheticAudio(data).then(res => {
                    if (res.data.code == 10000) {
                        this.SyntheticAudioList.splice(index, 1);
                        this.SyntheticAudioTotal--
                        this.$message({
                            message: '删除成功',
                            type: 'success'
                        });
                    }
                })
            }
        },
        handleDownloadItem(item) {
            // 实现下载逻辑
            console.log('下载:', item);
            // 这里可以添加实际的下载逻辑
        },
        // 刷新
        handleRefreshItem(item) {
            // console.log(item, 'asdasdasdfafsd')
            let list = this.SyntheticAudioList
            for (let i = 0; i < list.length; i++) {
                if (list[i].id == item.id) {
                    // console.log('赋值')
                    this.SyntheticAudioList[i] = item
                }
            }
        },
        // 合成音频列表分页
        handlePageChange(e) {
            this.SyntheticAudioPage = e
            this.getSyntheticAudioList()
        },
        verify() {
            if (this.activeIndex == 0) {

                this.dataItem.type = 1
                this.dataItem.synthetic_audio_id = this.selectedItem.id
                this.dataItem.audio_url = this.selectedItem.audio_url
            } else if (this.activeIndex == 1) {

                this.dataItem.type = 2
                this.dataItem.duration = Math.trunc(this.duration)
                this.dataItem.audio_url = this.audioPathOss

            }
            this.$emit('audioDra', false)
            this.$emit('audioData', this.dataItem)
        },
        // 上传音频
        audioPath(e) {
            console.log(e)
            this.audioPathOss = e
        },
        audioDuration(e) {
            this.duration = e
        },
        // 重新上传
        reupload() {
            this.audioPathOss = ''
        },
        // 搜索
        search() {
            this.SyntheticAudioPage = 1
            this.getSyntheticAudioList()
        },

        // 获取音频合成列表
        getSyntheticAudioList() {
            let params = {
                page: this.SyntheticAudioPage,
                page_size: 10,
                name: this.searchValue
            }
            getSyntheticAudioList(params).then(res => {
                if (res.data.code == 10000) {
                    this.SyntheticAudioList = res.data.data
                    this.SyntheticAudioTotal = res.data.total
                }
            })
        }
    },
    created() {
        this.getSyntheticAudioList()
    }
}
</script>
<style lang="less" scoped>
.audioDrawer {
    /deep/.rtl {
        width: 1008px !important;
        padding: 0 20px;
    }

    .actuate_box {
        .tab-header {
            display: flex;
            background-color: #D8D8D8;
            position: relative;
            overflow: hidden;
            border-radius: 50px;
            width: 966px;
            height: 70px;

            .tab-background {
                margin: 5px 10px;
                position: absolute;
                top: 0;
                left: 0;
                height: 60px;
                width: 473px;
                background-color: #fff;
                transition: transform 0.3s ease;
                border-radius: 50px;

                .tab-slider {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    height: 2px;
                    width: 50%;
                    background-color: #007bff;
                    transition: transform 0.3s ease;
                }
            }

            button {
                flex: 1;
                background: none;
                border: none;
                padding: 15px 30px;
                font-size: 16px;
                cursor: pointer;
                transition: color 0.3s ease;
                z-index: 1;
                position: relative;
            }

            .tab-header button.active {
                font-weight: bold;
                color: #007bff;
                border-radius: 50px;
            }
        }

        .textDriven {
            .textComposition {
                margin: 30px 0;
                
                .goCraft {
                    display: flex;
                    justify-content: space-between;
                    .left {
                        color: #3D3D3D;
                        font-size: 20px;
                    }

                    .right {
                        padding: 5px 10px;

                        cursor: pointer;
                        background-color: #3E7AFE;
                        border-radius: 5px;
                        color: #fff;
                    }
                }
            }

            .search {
                width: 250px;
                margin: 20px 0;
            }

            .block {
                margin-top: 20px;
                display: flex;
                justify-content: center;
            }


        }

        .audioDriver {

            .upload {
                margin-top: 20px;
                width: 960px;
                height: 320px;
                border: 1px dashed #000000;
                display: flex;
                justify-content: center;
                padding-top: 30px;

                .audioPlayer {
                    margin-top: 30px;

                    .reupload {
                        text-align: center;
                        cursor: pointer;
                        /*悬停变小手的属性*/
                        color: #3E7AFE;
                        font-size: 20px;
                        margin-top: 20px
                    }
                }
            }

            .suggestion {
                display: flex;
                height: 300px;

                .title {
                    font-size: 24px;
                    color: #3D3D3D;
                }

                .item {
                    .itemTile {
                        line-height: 50px;

                    }
                }

                .jianyiImg {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    img {
                        margin-top: 50px;
                    }

                    .confirm_cancellation {
                        display: flex;
                        justify-content: right;

                        .confirm {
                            margin-right: 10px;
                        }
                    }
                }
            }

        }

        .verify_cancel {
            position: sticky;
            bottom: 0;
            left: 0;
            display: flex;
            justify-content: right;
            background: #fff;
            padding: 10px 20px;

            div {
                cursor: pointer;
                /*悬停变小手的属性*/
                width: 167px;
                height: 55px;
                border-radius: 10px;
                line-height: 55px;
                text-align: center;
                font-size: 24px;
                margin-right: 15px;
                background: #3E7AFE;
                color: #fff;
            }
        }
    }
}
</style>