import Vue from 'vue'
import VueRouter from 'vue-router'
import { Message } from 'element-ui';


import Home from '../views/HomeView.vue'
// import HomeView from '../components/Main.vue'
import DigitalMan from '../assetsVue/Digitalman.vue'
import Index from '../components/index.vue'
import Audio from '../assetsVue/Audio.vue'
import Livebroadcast from '../Virtu_LiveBroadcast/Livebroadcast.vue'
import Video from '../Virtu_Video/Video.vue'
import MyWork from '../Virtu_Video/MyWork.vue'
import Drafts from '../Virtu_Video/Drafts.vue'
import VideoTable from '../Virtu_Video/VideoTable.vue'
import InteractiveDigitalPerson from '../Ai_GrassService/InteractiveDigitalPerson.vue'
import PhotoDigitalPerson from '../Ai_GrassService/PhotoDigitalPerson.vue'
import AiCreate from '../Ai_GrassService/AiCreate.vue'
import DressingDigitalPeople from '../Ai_GrassService/DressingDigitalPeople.vue'
import Matrix from '../Ai_GrassService/Matrix.vue'
import OpenPlatform from '../Ai_GrassService/OpenPlatform.vue'
import CreateVideo from '../views/CreateVideo.vue'


// 资产
import ImageCloning from '../assetsVue/assetsMain/ImageCloning.vue'
import PhotoCloning from '../assetsVue/assetsMain/PhotoCloning.vue'
import SoundCloning from '../assetsVue/assetsMain/SoundCloning.vue'




// 三级路由
import DigitalCloning from '../assetsVue/threeLevel/DigitalCloning.vue'
import MyImage from '../assetsVue/threeLevel/myImage.vue'
import ComImage from '../assetsVue/threeLevel/comImage.vue'


// AI服务
import BroadcastCopy from '../Ai_GrassService/components/BroadcastCopy.vue'
import CopyRewriting from '../Ai_GrassService/components/CopyRewriting.vue'
import TitleGeneration from '../Ai_GrassService/components/TitleGeneration.vue'
import LanguageTranslation from '../Ai_GrassService/components/LanguageTranslation.vue'
import ProductMarketing from '../Ai_GrassService/components/ProductMarketing.vue'
import NoteCreation from '../Ai_GrassService/components/NoteCreation.vue'
import myWorks from '../Ai_GrassService/components/myWorks.vue'

// 点数购买
import PointPurchase from '../views/PointPurchase.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/Home/index',
  },
  {
    path: '/Home',
    component: Home,
    children: [
      {
        path: 'index',
        component: Index
      },


      {
        path: 'digitalMan',
        component: DigitalMan,
        redirect: 'digitalCloning',
        children: [
          {
            path: 'digitalCloning',
            component: DigitalCloning
          },
          {
            path: 'myImage',
            component: MyImage,
            beforeEnter(to, from, next) {
              if (localStorage.getItem('token')) {    //权限控制的具体规则
                next()
              } else {
                Message.error('请先登录');
              }
            }
          },
          {
            path: 'comImage',
            component: ComImage
          }
        ]
      },
      {
        path: 'audio',
        component: Audio

      },
      {
        path: 'livebroadcast',
        component: Livebroadcast,
        beforeEnter() {
          Message.success('功能开发中，敬请期待！');
        }
      },
      {
        path: 'video',
        component: Video
      },
      {
        path: 'myWork',
        component: MyWork,
        beforeEnter(to, from, next) {
          if (localStorage.getItem('token')) {    //权限控制的具体规则
            next()
          } else {
            Message.error('请先登录');
          }
        }

      },
      {
        path: 'videoTable',
        component: VideoTable,
        beforeEnter(to, from, next) {
          if (localStorage.getItem('token')) {    //权限控制的具体规则
            next()
          } else {
            Message.error('请先登录');
          }
        }
      },
      {
        path: 'drafts',
        component: Drafts,
        beforeEnter() {
          Message.success('功能开发中，敬请期待！');
        }
      },
      {
        // 交互数字人
        path: 'interactiveDigitalPerson',
        component: InteractiveDigitalPerson,
        beforeEnter() {
          Message.success('功能开发中，敬请期待！');
        }
      },
      {
        // 照片数字人
        path: 'photoDigitalPerson',
        component: PhotoDigitalPerson,
        beforeEnter() {
          Message.success('功能开发中，敬请期待！');
        }
      }, {
        // AI创作
        path: 'AiCreate',
        component: AiCreate,

      }, {
        // 换衣数字人
        path: 'dressingDigitalPeople',
        component: DressingDigitalPeople,
        beforeEnter() {
          Message.success('功能开发中，敬请期待！');
        }
      }, {
        // 矩阵
        path: 'matrix',
        component: Matrix,
        beforeEnter() {
          Message.success('功能开发中，敬请期待！');
        }
      }, {
        // 开放平台
        path: 'openPlatform',
        component: OpenPlatform,

      }, {//创建视频
        path: 'CreateVideo',
        component: CreateVideo,
        beforeEnter() {
          Message.success('功能开发中，敬请期待！');
        }
      }
    ]
  },
  {
    path: '/ImageCloning',
    name: 'ImageCloning',
    component: ImageCloning,
    beforeEnter(to, from, next) {
      if (localStorage.getItem('token')) {    //权限控制的具体规则
        next()
      } else {
        Message.error('请先登录');
      }
    }
  },
  {
    path: '/PhotoCloning',
    name: 'PhotoCloning',
    component: PhotoCloning

  },
  {
    path: '/SoundCloning',
    name: 'SoundCloning',
    component: SoundCloning,
    beforeEnter(to, from, next) {
      if (localStorage.getItem('token')) {    //权限控制的具体规则
        next()
      } else {
        Message.error('请先登录');
      }
    }
  },
  {
    path: '/BroadcastCopy',
    name: 'BroadcastCopy',
    component: BroadcastCopy,
    beforeEnter(to, from, next) {
      if (localStorage.getItem('token')) {    //权限控制的具体规则
        next()
      } else {
        Message.error('请先登录');
      }
    }
  },
  {
    path: '/CopyRewriting',
    name: 'CopyRewriting',
    component: CopyRewriting,
  },
  {
    path: '/TitleGeneration',
    name: 'TitleGeneration',
    component: TitleGeneration,

  },
  {

    path: '/LanguageTranslation',
    name: 'LanguageTranslation',
    component: LanguageTranslation,
  },
  {
    path: '/ProductMarketing',
    name: 'ProductMarketing',
    component: ProductMarketing,
  },
  {
    path: '/NoteCreation',
    name: 'NoteCreation',
    component: NoteCreation,
  },
  {
    path: '/myWorks',
    name: 'myWorks',
    component: myWorks,
    beforeEnter(to, from, next) {
      if (localStorage.getItem('token')) {    //权限控制的具体规则
        next()
      } else {
        Message.error('请先登录');
      }
    }
  },




















  {
    path: '/404',
    name: 'NotFound',
    meta: {
      title: 'Page not found',
      isLogin: false
    },
    component: () => import('@/views/NotFound')
  },
  {
    path: '*',
    redirect: '/404'
  }










]

// const router = new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes
// })

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
