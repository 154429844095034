<template>
  <div class="editUserInfo">
    <!-- <div class="avatar"><img :src="item.avatar" alt=""></div> -->

    <el-upload class="avatar-uploader" :action="action" :show-file-list="false" :on-success="handleAvatarSuccess"
      :http-request="Upload">
      <img v-if="item.avatar" :src="item.avatar" class="avatar">
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>


    <div class="text" v-if="!item.avatar">点击修改</div>

    <div class="name">
      修改名称：<el-input type="text" :placeholder="item.name" maxlength="10" v-model="userInfo.store_name"></el-input>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="quxiao">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </span>
  </div>
</template>
<script>
import { client, getFileNameUUID } from '../../src/common/js/oss.js'
import { editUserInfor, getUserInfo } from '../../api/api'
export default {
  props: {
    item: {
      type: Object,
      default: {}
    },
  },
  data() {
    return {
      action: 'https://xinouyou.oss-cn-shanghai.aliyuncs.com',
      fileList: [],//文件列
      // showProgress: false,//进度条的显示
      dataObj: {
        region: 'oss-cn-shanghai',
        accessKeyId: 'LTAI5tRawNfEmzasXi9fFiT9',
        accessKeySecret: 'R4N8J4gkihfUwHXgrTH8CI5FwWEFW2',
        bucket: 'xinouyou'
      }, //存签名信息
      limit: 1,
      fullscreenLoading: false,//加载




      userInfo: {// 准备上传的用户信息
        avatar: '',
        store_name: ''
      },
    }
  },
  methods: {
    handleAvatarSuccess() {

    },

    Upload(e) {
      let file = e.file // 文件信息
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error('上传头像图片只能是PNG或JPG 格式!');
        return
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }

      const that = this;
      async function multipartUpload() {
        let temporary = file.name.lastIndexOf(".");
        let fileNameLength = file.name.length;
        let fileFormat = file.name.substring(
          temporary + 1,
          fileNameLength
        );
        let fileName = getFileNameUUID() + "." + fileFormat;
        client(that.dataObj).multipartUpload(`videoTest/${fileName}`, file, {

        }).then(result => {
          //上传成功返回值，可针对项目需求写其他逻辑
          let path = result.res.requestUrls[0]
          that.userInfo.avatar = path
          that.$emit('avatarPath', path)
        })
          .catch(err => {
            console.log("err:", err);
          });
      }
      multipartUpload();

    },

    submit() {
      if (!this.userInfo.avatar) return this.$message.error('请上传你的头像');
      if (!this.userInfo.store_name) return this.$message.error('请输入你的名称');
      console.log(this.userInfo)
      
      const loading = this.$loading({ lock: true, text: 'Loding', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.5)' });
     
      let data = {
        ...this.userInfo
      }
      editUserInfor(data).then(res => {
        if (res.data.code == 10000) {
          this.$message({ message: '修改成功', type: 'success' });
          loading.close();
          this.$emit("userAvatar", JSON.stringify(res.data.data));
          this.getUserInfo()
          this.$emit('editUserInfoShow',false)
        }
      })
    },
    quxiao(){
      this.$emit('editUserInfoShow',false)
    },

    // 获取用户信息
    getUserInfo() {
      getUserInfo().then(res => {
        if (res.data.code == 10000) {
          localStorage.setItem('userInfo', JSON.stringify(res.data.data));
        }
      })
    },
  },
  created() {
    console.log(this.item)
    this.userInfo.avatar = this.item.avatar
    this.userInfo.store_name = this.item.name
    this.editValue = this.item.name
  }
}

</script>
<style lang="less" scoped>
.editUserInfo {


  .avatar-uploader {
    display: flex;
    justify-content: center;

    img {
      width: 132px;
      height: 132px;
      border-radius: 50%;
    }
  }

  .text {
    text-align: center;
    line-height: 50px;
    cursor: pointer;
  }

  .name {
    display: flex;
    white-space: nowrap;
    line-height: 40px;
    margin-top: 40px;
  }

  .dialog-footer {
    margin-top: 30px;
    display: flex;
    justify-content: right;
  }
}
/deep/.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 50%;
    width: 132px;
    height: 132px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  /deep/.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  /deep/.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 132px;
    height: 132px;
    line-height: 132px;
    text-align: center;
  }
</style>