import { render, staticRenderFns } from "./videoUpload.vue?vue&type=template&id=c3090d64&scoped=true"
import script from "./videoUpload.vue?vue&type=script&lang=js"
export * from "./videoUpload.vue?vue&type=script&lang=js"
import style0 from "./videoUpload.vue?vue&type=style&index=0&id=c3090d64&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c3090d64",
  null
  
)

export default component.exports