<template>
    <nav class="side-nav">
        <ul>
            <li v-for="(item, index) in navItems" :key="index">
                <template v-if="item.children">
                    <div class="nav-item" @click="toggleSubMenu(index)" :class="{ 'active': isActive(item) }">
                        {{ item.title }}
                    </div>
                    <collapse-transition>
                        <ul v-show="item.isOpen" class="sub-menu">
                            <li v-for="(subItem, subIndex) in item.children" :key="subIndex">
                                <div class="link" @click="toLink(subItem)"  :class="{ 'active': isActive(subItem) }">
                                    <img v-if="!isActive(subItem)" :src="subItem.icon" alt=""> <img
                                        v-if="isActive(subItem)" :src="subItem.icon2" alt=""> {{ subItem.title }}
                                </div>
                            </li>
                        </ul>
                    </collapse-transition>
                </template>
                <div class="link" @click="toLink(item)" style="font-size: 20px; font-weight: bold;" v-else :to="item.path"
                    :class="{ 'active': isActive(item) }">
                    <img v-if="!isActive(item)" :src="item.icon" alt="">
                    <img v-if="isActive(item)" :src="item.icon2" alt="">
                    {{ item.title }}
                </div>
            </li>
        </ul>
    </nav>
</template>

<script>
import CollapseTransition from '@/common/CollapseTransition.vue';

export default {
    name: 'SideNav',
    components: {
        CollapseTransition
    },
    data() {
        return {
            navItems: [
                { title: '首页', path: '/Home/index', icon: require('../assets/home.png'), icon2: require('../assets/home2.png') },
                {
                    title: '资产',
                    isOpen: true,
                    children: [
                        { title: '数字人', path: '/Home/digitalMan/digitalCloning', icon: require('../assets/shuziren.png'), icon2: require('../assets/shuziren2.png') },
                        { title: '声音', path: '/Home/audio', icon: require('../assets/shengyin.png'), icon2: require('../assets/shengyin2.png') },

                    ]
                },
                {
                    title: '直播中心',
                    isOpen: true,
                    children: [
                        { title: '直播工作台', path: '/Home/livebroadcast', icon: require('../assets/zhibo.png'), icon2: require('../assets/zhibo2.png') },
                    ]
                },
                {
                    title: '创作中心',
                    isOpen: true,
                    children: [
                        { title: '视频工作台', path: '/Home/VideoTable', icon: require('../assets/shipin.png'), icon2: require('../assets/shipin2.png') },
                        { title: '我的作品', path: '/Home/myWork', icon: require('../assets/zuopin.png'), icon2: require('../assets/zuopin2.png') },
                        { title: '草稿箱', path: '/Home/drafts', icon: require('../assets/caogaoxiang.png'), icon2: require('../assets/caogaoxiang2.png') },
                    ]
                },
                {
                    title: 'AI服务',
                    isOpen: true,
                    children: [
                        { title: '交互式数字人', path: '/Home/interactiveDigitalPerson', icon: require('../assets/jiaohu.png'), icon2: require('../assets/jiaohu2.png') },
                        { title: '照片数字人', path: '/Home/photoDigitalPerson', icon: require('../assets/zhaopian.png'), icon2: require('../assets/zhaopian2.png') },
                        { title: 'AI创作', path: '/Home/AiCreate', icon: require('../assets/aichuangzuo.png'), icon2: require('../assets/aichuangzuo2.png') },
                        { title: '换衣数字人', path: '/Home/dressingDigitalPeople', icon: require('../assets/huanyi.png'), icon2: require('../assets/huanyi2.png'), },
                        { title: 'AI短视频矩阵', path: '/Home/matrix', icon: require('../assets/juzheng.png'), icon2: require('../assets/juzheng2.png') },
                        // { title: '开放平台', path: '/Home/OpenPlatform',query: { url: 'https://open.oouu.cc/doc/#/' }, icon: require('../assets/kaifang.png'), icon2: require('../assets/kaifang2.png') },
                    ]
                },

            ]
        }
    },
    methods: {
        toLink(e){
            if(e.query !== undefined){
                // window.location.href=e.query.url//当前页面打开
                window.open(e.query.url)//新页面打开
                return
            }
           this.$router.push(e.path)
        },
        toggleSubMenu(index) {
            this.navItems[index].isOpen = !this.navItems[index].isOpen;
        },
        isActive(item) {
            if (item.path) {
                return this.$route.path === item.path;
            } else if (item.children) {
                return item.children.some(child => this.$route.path === child.path);
            }
            return false;
        }
    }
}
</script>

<style lang="less" scoped>
::-webkit-scrollbar {
    /*隐藏滚轮*/
    display: none;
}

.side-nav {
    margin-left: 20px;
    width: 200px;
    background-color: #fff;
    /* height: 100%; */
    /* 设置高度为100% */
    overflow-y: auto;
    /* 添加垂直滚动条，以防内容过长 */
    border-radius: 10px;
    width: 270px;
    height: 840px;
}

ul {
    list-style-type: none;
    padding: 20px 0;
    margin: 0;
}

li {
    padding: 0;
    /* 移除li的内边距 */
}

img {
    margin-right: 5px;
}


.nav-item {
    text-decoration: none;
    color: #333;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px 20px;
    /* 将内边距移到这里 */
    border-radius: 4px;
    margin: 2px 10px;
    transition: background-color 0.3s ease;
    font-weight: bold;
    font-size: 20px;
    margin-top: 15px;
}

.link {
    text-decoration: none;
    color: #333;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px 20px;
    /* 将内边距移到这里 */
    border-radius: 4px;
    margin: 2px 10px;
    transition: background-color 0.3s ease;
    font-weight: bold;

}

.link:hover,
.nav-item:hover {
    background-color: #D3E4FF;
}

.link.active,
.nav-item.active {
    background: #92BAFF;
    font-weight: bold;
    color: #fff;
}


.sub-menu {
    padding: 0;
    overflow: hidden;
    font-size: 14px;
    font-weight: normal;
}

.sub-menu .link {
    padding: 10px 20px 10px 30px;
}
</style>