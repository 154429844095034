<template>
  <table class="digital-human-grid">
    <thead>
      <tr>
        <th></th>
        <th>名称</th>
        <th>字数</th>
        <th>状态</th>
        <th>操作</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in items" :key="index" @click="selectRow(index, item)"
        :class="{ 'disabled': !isCompleted(item) }">
        <td>
          <label class="custom-radio">
            <input type="radio" :name="radioGroupName" :value="index" v-model="selectedIndex"
              :disabled="!isCompleted(item)" @click.stop>
            <span class="checkmark"></span>
          </label>
        </td>
        <td>{{ item.name }}</td>
        <td>{{ item.length }}</td>
        <td>
          <div v-if="item.progress == 1">{{ '合成中' }}</div>
          <div v-if="item.progress == 2">{{ '合成成功' }}</div>
          <div v-if="item.progress == 3">{{ '合成失败' }}</div>
        </td>
        <!-- status -->
        <td>
          <button v-if="!isPlaying || playingIndex !== index" @click.stop="playAudio(item, index)"
            :disabled="!isCompleted(item)">
            试听
          </button>
          <button v-else @click.stop="pauseAudio()" :disabled="!isCompleted(item)">
            暂停
          </button>
          <button @click.stop="deleteItem(item)" :disabled="item.progress == '1' || item.progress == '3'">删除</button>
          <button @click.stop="downloadItem(item)" :disabled="!isCompleted(item)">下载</button>
          <button @click.stop="refreshItem(item, index)" :disabled="isCompleted(item)">刷新</button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { refreshSyntheticAudio } from '../api/api'
export default {
  name: 'DigitalHumanGrid',
  props: {
    items: {
      type: Array,
      required: true
    },

  },
  data() {
    return {
      selectedIndex: null,
      isPlaying: false,
      playingIndex: null,
      audio: null,
      radioGroupName: 'digitalHumanRadio',
      time: null,
    }
  },
  methods: {
    selectRow(index, item) {
      if (this.isCompleted(item)) {
        this.selectedIndex = this.selectedIndex === index ? null : index;
        this.$emit('selection-change', this.selectedIndex !== null ? this.items[this.selectedIndex] : null);
      }
    },
    playAudio(item, index) {
      if (this.isCompleted(item) && item.audio_url) {
        if (this.audio) {
          this.audio.pause();
        }
        this.audio = new Audio(item.audio_url);
        this.audio.play();
        this.isPlaying = true;
        this.playingIndex = index;

        this.audio.onended = () => {
          this.isPlaying = false;
          this.playingIndex = null;
        };
      }
    },
    // 播放
    pauseAudio() {
      if (this.audio) {
        this.audio.pause();
        this.isPlaying = false;
        this.playingIndex = null;
      }
    },
    // 删除
    deleteItem(item) {
      if (this.isCompleted(item)) {
        this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$emit('delete-item', item);
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      }
    },
    // 下载
    downloadItem(item) {
      if (this.isCompleted(item) && item.audio_url) {
        const link = document.createElement('a');
        link.href = item.audio_url;
        link.download = `${item.name}.wav`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    // 刷新单条数据
    refreshItem(item, index) {
      let that = this
      if (that.time) {
        clearTimeout(that.time)
      }
      that.time = setTimeout(function () {
        let data = {
          id: item.id
        }
        refreshSyntheticAudio(data).then(res => {
          if (res.data.code == 10000) {
            that.$message({
              message: '刷新成功',
              type: 'success'
            });
            that.$emit('refresh-item', res.data.data);
            that.$forceUpdate()
          }
        })
        that.time = null;
      }, 1000)




    },
    isCompleted(item) {
      return item.progress == '2';
    }
  },
  watch: {
    selectedIndex(newVal) {
      this.$emit('selection-change', newVal !== null ? this.items[newVal] : null);
    },

  }
}
</script>

<style scoped>
.digital-human-grid {
  width: 100%;
  border-collapse: collapse;
}

.digital-human-grid th,
.digital-human-grid td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
  /* 文字居中 */
  vertical-align: middle;
}

.digital-human-grid th {
  background-color: #f2f2f2;
}

.digital-human-grid tr:nth-child(even) {
  background-color: #f9f9f9;
}

.digital-human-grid tr {
  cursor: pointer;
}

.digital-human-grid tr.disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.digital-human-grid button {
  cursor: pointer;
  margin: 0 5px;
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  background-color: #4CAF50;
  color: white;
}

.digital-human-grid button:hover {
  background-color: #45a049;
}

.digital-human-grid button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
  background-color: #cccccc;
}

/* 自定义复选框样式 */
.custom-radio {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
}

/* 保持其他样式不变 */

/* 确保复选框在单元格中居中 */
td:first-child {
  text-align: center;
}

.custom-radio {
  margin: 0 auto;
}

/* 其他样式保持不变 */
</style>