import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
Vue.prototype.$axios = axios;

Vue.prototype.$EventBus = new Vue()


import commonFun from './common/js/common.js'
Object.keys(commonFun).forEach((k) => {
  Vue.prototype[k] = commonFun[k]
})

// 引入less
import less from 'less'
Vue.use(less)

// 引入element
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
  beforeCreate(){
    Vue.prototype.$bus=this
  }
}).$mount('#app')
