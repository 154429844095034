/****   request.js   ****/
// 导入axios
import axios from 'axios'
// 使用element-ui Message做消息提醒
import { Message } from 'element-ui';
//1. 创建新的axios实例，
const service = axios.create({
    // 公共接口--这里注意后面会讲
    baseURL: process.env.BASE_API,
    // 超时时间 单位是ms，这里设置了5s的超时时间
    timeout: 10 * 1000
})

// 2.请求拦截器
service.interceptors.request.use(config => {
    //发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等

    config.data = JSON.stringify(config.data); //数据转化,也可以使用qs转换

    config.headers = {
        'Content-Type': 'application/json',//配置请求头
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': 'Bearer ' + localStorage.getItem('token') || '',
    }

    return config
}, error => {
    return Promise.reject(error)
})

// 3.响应拦截器
service.interceptors.response.use(response => {
    //接收到响应数据并成功后的一些共有的处理，关闭loading等
    if (response.status == 200) return response
    console.log(response.status)
}, error => {
    /***** 接收到异常响应的处理开始 *****/
    if (error && error.response) {
        // 1.公共错误处理
        // 2.根据响应码具体处理
        if (error.response.status == 401) {
            console.log('去登陆')
            localStorage.removeItem('token')
            localStorage.removeItem('userInfo')
        }
        if (error.response.status !== 200) {
            let errData = error.response.data
            if (errData.code == 500) {
                Message.error(errData.message)
            }
            // if (errData.code !== 10000) {
            //     // Message.error(errData.message)
            // }
        }
    } else {
        // 超时处理
        if (JSON.stringify(error).includes('timeout')) {
            Message.error('服务器响应超时，请刷新当前页')
        }
        error.message = '连接服务器失败'
    }
    /***** 处理结束 *****/
    //如果不需要错误处理，以上的处理过程都可省略
    return Promise.resolve(error.response)
})
//4.导入文件
export default service