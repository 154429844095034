<template>
    <div class="content">
        <div class="voiceList" v-if="list.length !== 0">
            <div class="box">
                <div class="item" v-for="(item, index) in list" :key="index">
                    <div class="avatar" v-if="item.gender == 1"><img src="../../src/assets/nan.png" alt=""></div>
                    <div class="avatar" v-if="item.gender == 2"><img src="../../src/assets/nv.png" alt=""></div>
                    <div class="avatar" v-if="item.gender == 0"><img src="../../src/assets/xingbieweizhi.png" alt=""></div>
                    <div class="userInfo">
                        <div class="name">{{ item.name }}</div>
                        <div class="tag">
                            <div v-for="itemTag in item.tag" :key="itemTag">{{ itemTag }}</div>
                        </div>
                    </div>
                    <div class="playBack_pause" v-if="item.listen_url">
                        <div class="playBack" @click="playMyAudio(item.listen_url, index)" v-if="item.show == false"><img
                                src="../../src/assets/bofang.png" alt=""></div>
                        <div class="playBack" @click="endMyAudio( index)" v-if="item.show == true"><img
                                src="../../src/assets/zanting.png" alt=""></div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="list.length == 0"><noData/></div>
    </div>
</template>
<script>
import noData from '../../components/noData.vue'

export default {
    components:{
        noData
    },
    name: 'publicVoice',
    props: {
        list: {
            type: Array,
            default: () => ([])
        },
    },
    data() {
        return {
            input1: '',
            audio: new Audio(),
            playIndex: ''//正在播放的音频的Index
        }
    },
    methods: {
        playMyAudio(url, index) {
            // 判断是否是同一个音频，不是的话其他为false
            for (let i = 0; i < this.list.length; i++) {
                if (i !== index) {
                    this.list[i].show = false
                }
            } 
            this.list[index].show = true//修改显示
            this.playIndex = index
            this.audio.src = url
            this.audio.play();//播放
            this.$emit('comIndex',index)
            this.audio.onended = () => {
                console.log('要结束了')
                this.list[index].show = false
            }
        },
        endMyAudio(index) {
            this.audio.pause();//暂停
            // this.list[index].show = false
            this.$emit('endAudio',index)
        },
    },
}
</script>
<style lang="less" scoped>
.content {
    .voiceList {
        .box {
            display: flex;
            flex-wrap: wrap;
            text-align: justify;

            .item {
                margin-right: 30px;
                margin-left: 25px;
                margin-top: 20px;
                width: 458px;
                // height: 85px;
                padding: 10px 10px;
                border-radius: 15px;
                opacity: 1;
                box-sizing: border-box;
                border: 1px solid #CCCACA;
                display: flex;

                .avatar {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    img {
                        width: 82px;
                        height: 82px;
                    }
                }

                .userInfo {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    margin-left: 10px;
                    width: 332px;
                    overflow: hidden;

                    .name {
                        white-space: nowrap; //强制不换行
                        font-size: 24px;
                        color: #3D3D3D;
                    }

                    .tag {
                        display: flex;

                        div:not(:first-child) {
                            margin-left: 5px;
                        }

                        div {
                            white-space: nowrap; //强制不换行
                            color: #3D3D3D;
                            font-size: 18px;
                            padding: 2px 5px;
                            background-color: #EDEEF7;
                            border-radius: 4px;
                        }
                    }
                }

                .playBack_pause {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin-right: 15px;

                    img {
                        cursor: pointer;
                        width: 44px;
                        height: 44px;
                    }
                }
            }
        }
    }
}
</style>