<template>
    <div class="videoTable">
        <div class="route_v">
            <div class="item" @click="selectItem(item, index)" :class="{ active: selectIndex == index ? 'active' : '' }"
                :style="{ background: item.back, color:item.color }" v-for="(item, index) in routeList" :key="index">
                <div class="name">{{ item.name }}</div>
                <div class="text">{{ item.text }}</div>
                <div class="img"><img :src="item.img" alt=""></div>
            </div>
        </div>
        <div>
            <CreateVideo :type="selectTabItem.type" :number="selectTabItem.number" />
        </div>
    </div>
</template>
<script>
import imageModule from '../../components/digitalImageModule/imageModule.vue'
import CreateVideo from '../views/CreateVideo.vue'
export default {
    components: {
        imageModule,
        CreateVideo
    },
    data() {
        return {
            routeList: [
                {
                    name: 'V1合成',
                    text: '基本还原人物口型特征',
                    back: 'linear-gradient(90deg, #AB7BFF 0%, rgba(95, 204, 255, 0.87) 100%)',
                    img: require('../assets/vtImg1.gif'),
                    color: '#fff',
                    type: 1,
                    number: 600,//点数消耗
                }, {
                    name: 'V2合成',
                    text: '无限并发，合成速度加快',
                    back: ' linear-gradient(90deg, #00D9FA 0%, rgba(177, 160, 254, 0.69) 100%)',
                    img: require('../assets/vtImg2.gif'),
                    color: '#fff',

                    type: 2,
                    number: 1200
                }, {
                    name: 'V3合成',
                    text: '复刻人物表现的细节神态',
                    back: '#F0F0F0',
                    color: '#646464',
                    img: require('../assets/vtImg3.gif'),
                    type: 3,
                }, {
                    name: 'V4合成',
                    text: '快速生成数字人，马上体验',
                    back: '#F0F0F0',
                    color: '#646464',
                    img: require('../assets/vtImg4.gif'),
                    type: 4
                }, {
                    name: 'V5合成',
                    text: '一比一还原人物动态神情',
                    back: 'linear-gradient(90deg, rgba(0, 123, 255, 0.69) 0%, rgba(195, 105, 255, 0.59) 100%',
                    img: require('../assets/vtImg5.gif'),
                    color: '#fff',

                    type: 5,
                    number: 3000
                }
            ],
            selectIndex: 0,
            selectTabItem: {}
        }
    },
    methods: {
        selectItem(item, index) {
            if (item.type == 3 || item.type == 4) return this.$message({ message: '暂未开放', type: 'warning' });
            this.selectTabItem = item
            this.selectIndex = index
        },
        vtLocalStorageSet() {
            localStorage.setItem('vtItem', JSON.stringify(this.selectTabItem))
            localStorage.setItem('vtIndex', JSON.stringify(this.selectIndex))
        },
        vtLocalStorageGet(){
            this.selectTabItem =    JSON.parse(localStorage.getItem('vtItem'))
            this.selectIndex =  localStorage.getItem('vtIndex')
        },
    },


    mounted() {
        window.addEventListener('beforeunload', (e) => this.vtLocalStorageSet());
        window.addEventListener('unload', this.vtLocalStorageGet());
    },
    created() {
        if (this.$route.query.type) {
            let type = this.$route.query.type
            let list = this.routeList
            for (let i = 0; i < list.length; i++) {
                if (list[i].type == type) {
                    this.selectTabItem = list[i]
                    this.selectIndex = i
                }
            }
        } else {
            this.selectTabItem = this.routeList[0]
        }


    }
}
</script>
<style lang="less" scoped>
.videoTable::-webkit-scrollbar {
    display: none
}

.videoTable {
    .route_v {
        padding: 13px 20px;
        display: flex;
        justify-content: space-between;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #fff;

        .item:hover {
            img {
                //鼠标悬停时激活
                // opacity: 0.2; //透明度
                transition: 0.9s; //动画过渡的更加顺滑
                transform: scale(1.2); //放大倍数
            }
        }

        .active {
            border: 1px solid #9F9F9F;
            box-shadow: 2px 2px 16px 1px rgba(62, 122, 254, 0.6);
        }

        .item {
            transition: all 0.2s;
            width: 255px;
            height: 132px;
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 15px;
            position: relative;
            cursor: pointer;

            .name {
                font-size: 28px;
                font-weight: bold;
                // color: #FFFFFF;
                z-index: 1;
            }

            .text {
                font-size: 24px;
                font-weight: normal;
                line-height: normal;
                // color: #FFFFFF;
                z-index: 1;
                width: 182px;
            }

            .img {
                position: absolute;
                right: 20px;
                top: 0;

                img {
                    transition: all 0.4s ease 0s;
                    width: 138px;
                    height: 138px;
                }
            }

        }
    }
}
</style>