<template>
    <div class="myWorks">
        <div style="padding: 0 20px;">
            <Header />
        </div>
        <div class="contentBox">
            <div class="header">我的作品</div>
            <div class="title_search">
                <div class="title">
                    <div class="img"><img src="../assets/myWorks.png" alt=""></div>
                    <div class="">全部作品</div>
                </div>
                <div class="search">
                    <el-input placeholder="请输入内容" prefix-icon="el-icon-search" v-model="searchValue">
                    </el-input>
                </div>
            </div>
            <div class="tabList">
                <div class="item"  @click="clickTab(index)"
                 :style="{ background: (tabIndex == index ? item.back : ''), color: (tabIndex == index ? '#fff' : '#000') }"
                v-for="(item, index) in tabList" :key="index">
                    {{ item.name }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Header from '@/components/Header.vue'
export default {
    components: {
        Header
    },
    data() {
        return {
            searchValue: '',//搜索
            tabList: [
                {
                    name: '口播文案',
                    type: 1,
                    back: ' linear-gradient(90deg, #4D88FF 0%, #7BB2FF 100%), linear-gradient(90deg, #FFF2E8 0%, #CBF699 100%), #D8D8D8'
                }, {
                    name: '文案改写',
                    type: 2,
                    back: 'linear-gradient(270deg, #B0C2FF 0%, #C79BFF 100%), linear-gradient(90deg, #B0FFC7 0%, #FFA7C9 100%), #D8D8D8'
                }, {
                    name: '标题生成',
                    type: 3,
                    back: 'linear-gradient(90deg, #78B5FF 0%, #E1CCFA 100%, #00D5FF 100%), linear-gradient(90deg, #78B5FF 0%, #FACCCC 100%, #00D5FF 100%), #D8D8D8'
                }, {
                    name: '语言翻译',
                    type: 4,
                    back: 'linear-gradient(90deg, #5493FF 0%, rgba(128, 219, 255, 0.65) 100%), linear-gradient(90deg, #857AFF 0%, rgba(105, 212, 255, 0.65) 100%), #D8D8D8'
                }, {
                    name: '产品营销',
                    type: 5,
                    back: 'linear-gradient(90deg, #00D9FA 0%, rgba(177, 160, 254, 0.69) 100%), linear-gradient(90deg, #00D9FA 0%, rgba(177, 160, 254, 0.69) 100%), #D8D8D8'
                }, {
                    name: '笔记创作',
                    type: 6,
                    back: 'linear-gradient(270deg, #C3BEFE 0%, #847EFF 100%), linear-gradient(90deg, #51FFC8 0%, #A7D1FF 100%), #D8D8D8'
                },
            ],
            tabIndex: '0',//选中的tab的Index
        }
    },
    methods:{
        clickTab(index) {
            this.tabIndex = index
        },
    }
}
</script>
<style lang="less" scoped>
.myWorks {
    .contentBox {
        margin: 0 20px 20px 20px;
        background-color: #fff;
        height: 840px;
        border-radius: 20px;

        .header {
            height: 50px;
            line-height: 50px;
            text-align: center;
            font-size: 20px;
            color: #3D3D3D;
            border-bottom: 1px solid #E7E7E7;
        }
    }
}

.title_search {
    display: flex;
    justify-content: space-between;
    line-height: 70px;
    padding-right: 20px;

    .title {
        display: flex;

        img {
            width: 70px;
            height: 70px;
        }
    }
}

.tabList {
    padding: 0 20px;
    display: flex;
    justify-content: space-between;

    .item {
        width: 174px;
        padding: 10px 0 ;
        border-radius: 5px;
        background: #D8D8D8;
        text-align: center;
        font-size: 20px;
        cursor: pointer;
    }
}
</style>