<template>
    <div class="audioMini">
        <div  v-for="(item, index) in list" :key="index">
            <div  :class="[itemIndex == index?'itemmm':'item']"  @click="clickItem(item,index)">
                <div class="avatar">
                    <div class="img" v-if="item.gender == 1"><img src="../src/assets/nan.png" alt=""></div>
                    <div class="img" v-if="item.gender == 2"><img src="../src/assets/nv.png" alt=""></div>
                    <div class="img" v-if="item.gender == 0"><img src="../src/assets/xingbieweizhi.png" alt=""></div>

                    <div class="play" v-if="!item.show" @click.stop="play(item, index)"><img src="../src/assets/bofang2.png"
                            alt=""></div>
                    <div class="play" v-if="item.show" @click.stop="pause(item, index)"><img src="../src/assets/zanting.png"
                            alt=""></div>

                </div>
                <div class="name_tag">
                    <div class="nmae">{{ item.name }}</div>
                    <div class="tag">
                        <div v-for="(tagItem, tagIndex) in item.tag" :key="tagIndex">
                            <div class="tagItem">
                                {{ tagItem }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
export default {
    props: {

        list: {
            type: Array,
            default: []
        },

    },
    data() {
        return {
            playIndex: '',//正在播放的音频的Index
            itemIndex:'-1',//选中的item
            audio: new Audio(),
        }
    },
    methods: {
        play(item, index) {
            this.audio.pause();
            this.audio.src = item.listen_url
            // 判断是否是同一个音频，不是的话其他为false
            for (let i = 0; i < this.list.length; i++) {
                if (i !== index) {
                    this.list[i].show = false
                } else {
                    this.list[index].show = true
                }
            }
            this.audio.play();
            this.audio.onended = () => {
                console.log('要结束了')
                item.show = false
            }
        },
        pause(item) {
            this.audio.pause();
            // item.show = false
            for (let i = 0; i < this.list.length; i++) {
                this.list[i].show = false
            }
        },
        clickItem(item,index){
            this.itemIndex = index
            console.log(item)
            // 选中的item的id
            this.$emit('audioId',item.model_id) 
        }
    }
}
</script>
<style lang="less" scoped>
.audioMini {
    display: flex;
    flex-wrap: wrap;
   
    .item {
        width: 208px;
        height: 54px;
        border-radius: 5px;
        border: 1px solid #CCCACA;
        display: flex;
        margin-right: 10px;
        margin-bottom: 10px;
        .avatar {
            margin: 5px;
            width: 44px;
            height: 44px;
            position: relative;

            .img {
                img {
                    width: 44px;
                    height: 44px;
                }
            }

            .play {
                position: absolute;
                top: 11.5px;
                left: 11.5px;

                img {
                    width: 21px;
                    height: 21px;
                }
            }
        }

        .name_tag {
            flex: 1;
            margin: 5px 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .tag {
                display: flex;

                .tagItem {
                    font-size: 10px;
                    color: #3D3D3D;
                    background-color: #EDEEF7;
                    margin-right: 5px;
                }
            }
        }
    }

    .itemmm{
        border: 1px solid #3E7AFE;
        width: 208px;
        height: 54px;
        border-radius: 5px;
        display: flex;
        margin-right: 10px;
        margin-bottom: 10px;
        .avatar {
            margin: 5px;
            width: 44px;
            height: 44px;
            position: relative;

            .img {
                img {
                    width: 44px;
                    height: 44px;
                }
            }

            .play {
                position: absolute;
                top: 11.5px;
                left: 11.5px;

                img {
                    width: 21px;
                    height: 21px;
                }
            }
        }

        .name_tag {
            flex: 1;
            margin: 5px 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .tag {
                display: flex;

                .tagItem {
                    font-size: 10px;
                    color: #3D3D3D;
                    background-color: #EDEEF7;
                    margin-right: 5px;
                }
            }
        }
    }
}
</style>